import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class KeycloakInfoService {
  PATH = 'api/info';

  constructor(private http: HttpClient) {}

  getGroups(): Observable<string[]> {
    return this.http.get<string[]>(`${this.PATH}/groups`);
  }

  getLocale(): Observable<string[]> {
    return this.http.get<string[]>(`${this.PATH}/locale`);
  }

  getTime(): Observable<string[]> {
    return this.http.get<string[]>(`${this.PATH}/time`);
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${this.PATH}/roles`);
  }
}
