import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-info-modal',
  template: `
    <div class="p-4">
      <p>{{ config.data?.content }}</p>
      <div class="flex justify-content-end mt-4">
        <button pButton type="button" [label]="'primeng.accept' | translate" (click)="close()"></button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoModalComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  close() {
    this.ref.close();
  }
}
