import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HelpDTO } from '../model/admin-dto';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  PATH = 'api/help';

  constructor(private http: HttpClient) {}

  getHelp(locale: string): Observable<HelpDTO> {
    return this.http.get<HelpDTO>(`${this.PATH}?locale=${locale}`);
  }

  saveHelp(help: HelpDTO) {
    return this.http.post(`${this.PATH}`, help);
  }
}
