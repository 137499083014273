import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://sso-pansa.test.pansa.io/auth/',
  realm: 'WebRqaAdmin',
  clientId: 'webrqa-admin-console'
};

export const environment = {
  production: false,
  keycloakConfig
};
