import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-confirm-modal',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule],
  template: `
    <div class="p-4">
      <p>{{ config.data?.content }}</p>
      <div class="flex justify-content-end gap-2 mt-4">
        <button
          pButton
          type="button"
          [label]="'primeng.reject' | translate"
          class="p-button-outlined"
          (click)="close(false)"
        ></button>
        <button pButton type="button" [label]="'primeng.accept' | translate" (click)="close(true)"></button>
      </div>
    </div>
  `,
  styles: []
})
export class ConfirmModalComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  close(result: boolean) {
    this.ref.close(result);
  }
}
