import { Component, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { LoadingComponent } from 'src/app/ui/loading/loading.component';

import { LayoutService } from '../service/layout.service';

import { RqaTopbarComponent } from './app.topbar';
import { RqaSidebarComponent } from './app.sidebar';
import { RqaFooterComponent } from './app.footer';

@Component({
  selector: 'rqa-layout',

  standalone: true,

  imports: [CommonModule, RqaTopbarComponent, RqaSidebarComponent, RouterModule, RqaFooterComponent, LoadingComponent],

  template: `<div class="layout-wrapper" [ngClass]="containerClass">
    <rqa-topbar></rqa-topbar>

    <rqa-sidebar></rqa-sidebar>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-outlet></router-outlet>
      </div>

      <rqa-footer></rqa-footer>
    </div>

    <div class="layout-mask animate-fadein"></div>
    <rqa-loading></rqa-loading>
  </div> `
})
export class RqaLayoutComponent implements OnDestroy {
  overlayMenuOpenSubscription: Subscription;

  menuOutsideClickListener: (() => void) | undefined;

  @ViewChild(RqaSidebarComponent) appSidebar!: RqaSidebarComponent;

  @ViewChild(RqaTopbarComponent) appTopBar!: RqaTopbarComponent;

  constructor(
    public layoutService: LayoutService,

    public renderer: Renderer2,

    public router: Router
  ) {
    this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
      if (!this.menuOutsideClickListener) {
        this.menuOutsideClickListener = this.renderer.listen('document', 'click', (event) => {
          if (this.isOutsideClicked(event)) {
            this.hideMenu();
          }
        });
      }

      if (this.layoutService.layoutState().staticMenuMobileActive) {
        this.blockBodyScroll();
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hideMenu();
    });
  }

  isOutsideClicked(event: MouseEvent) {
    const sidebarEl = document.querySelector('.layout-sidebar');

    const topbarEl = document.querySelector('.layout-menu-button');

    const eventTarget = event.target as Node;

    return !(
      sidebarEl?.isSameNode(eventTarget) ||
      sidebarEl?.contains(eventTarget) ||
      topbarEl?.isSameNode(eventTarget) ||
      topbarEl?.contains(eventTarget)
    );
  }

  hideMenu() {
    this.layoutService.layoutState.update((prev) => ({
      ...prev,

      overlayMenuActive: false,

      staticMenuMobileActive: false,

      menuHoverActive: false
    }));

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();

      this.menuOutsideClickListener = undefined;
    }

    this.unblockBodyScroll();
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),

        ' '
      );
    }
  }

  get containerClass() {
    return {
      'layout-overlay': this.layoutService.layoutConfig().menuMode === 'overlay',

      'layout-static': this.layoutService.layoutConfig().menuMode === 'static',

      'layout-static-inactive':
        this.layoutService.layoutState().staticMenuDesktopInactive &&
        this.layoutService.layoutConfig().menuMode === 'static',

      'layout-overlay-active': this.layoutService.layoutState().overlayMenuActive,

      'layout-mobile-active': this.layoutService.layoutState().staticMenuMobileActive
    };
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}
