<div class="p-4">
  <div class="field mb-4">
    <div class="flex align-items-center gap-2 mb-3">
      <p-checkbox
        [(ngModel)]="message.sendToAll"
        [binary]="true"
        inputId="sendToAll"
        (ngModelChange)="onSendToAllChange()"
      ></p-checkbox>
      <label for="sendToAll">{{ 'messages.create.sendToAll' | translate }}</label>
    </div>

    <div *ngIf="!message.sendToAll">
      <label class="block mb-2">{{ 'messages.create.recipients' | translate }}</label>
      <p-multiSelect
        [(ngModel)]="message.recipient"
        [options]="availableRecipients"
        optionLabel="name"
        optionValue="code"
        [filter]="true"
        [showToggleAll]="true"
        [maxSelectedLabels]="100"
        display="chip"
        [placeholder]="'messages.create.recipients.placeholder' | translate"
        class="w-full multiselect-custom"
        [panelStyle]="{ 'min-width': '100%' }"
      ></p-multiSelect>
    </div>
  </div>

  <div class="field mb-4">
    <label for="content" class="block mb-2">{{ 'messages.create.content' | translate }}</label>
    <p-editor
      [(ngModel)]="message.content"
      [style]="{ height: '320px' }"
      [placeholder]="'messages.create.content.placeholder' | translate"
    ></p-editor>
  </div>

  <div class="flex justify-content-end gap-2">
    <button
      pButton
      type="button"
      [label]="'primeng.cancel' | translate"
      class="p-button-outlined"
      (click)="cancel()"
    ></button>
    <button
      pButton
      type="button"
      [label]="'messages.create.send' | translate"
      (click)="send()"
      [disabled]="!message.content || (message.recipient.length === 0 && !message.sendToAll)"
    ></button>
  </div>
</div>
