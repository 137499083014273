import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EditorModule } from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DomSanitizer } from '@angular/platform-browser';

import { HelpService } from '../../../services/help.service';
import { HelpDTO } from '../../../model/admin-dto';

@Component({
  selector: 'rqa-other',
  template: `
    <div class="card">
      <p-card>
        <div class="grid">
          <div class="col-6">
            <h3>Edited by: {{ help.username }}</h3>
          </div>
          <div class="col-6">
            <h3>Last modified: {{ help.updated | date: 'yyyy-MM-dd HH:mm:ss' }}</h3>
          </div>
        </div>
        <div class="mt-4">
          <h3>For all users:</h3>
          <p-editor [(ngModel)]="help.content" [style]="{ height: '320px' }"></p-editor>
        </div>
        <div class="mt-4">
          <h3>For draw users:</h3>
          <p-editor [(ngModel)]="help.contentForDraw" [style]="{ height: '320px' }"></p-editor>
        </div>
        <div class="flex justify-content-end mt-4">
          <p-button label="Save" icon="pi pi-save" (onClick)="save()"></p-button>
        </div>
      </p-card>
    </div>
  `,
  imports: [CommonModule, FormsModule, EditorModule, ButtonModule, CardModule, EditorModule],
  standalone: true
})
export class OtherComponent implements OnInit {
  help: HelpDTO = {
    content: '',
    contentForDraw: '',
    locale: '',
    username: '',
    updated: new Date()
  };

  @Input() locale = '';

  constructor(
    private helpService: HelpService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.helpService.getHelp(this.locale).subscribe(
      (value) => {
        this.help = value;
      },
      () => {
        this.help.content = '';
        this.help.locale = this.locale;
      }
    );
  }

  save() {
    this.helpService.saveHelp(this.help).subscribe();
  }
}
