<div class="w-full p-4">
  <form [formGroup]="userForm" (ngSubmit)="handleFormSubmit()">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
      <div class="w-full">
        <div class="mb-2">
          <label for="username" class="block mb-1 text-sm">{{ 'users.form.username' | translate }}</label>
          <input
            id="username"
            type="text"
            pInputText
            class="w-full"
            formControlName="username"
            (input)="inputUsername()"
            [class.ng-invalid]="userForm.get('username')?.invalid && userForm.get('username')?.touched"
          />
          <small class="text-red-500 block text-xs" *ngIf="errors.has('username')">{{ errors.get('username') }}</small>
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="email" class="block mb-1 text-sm">{{ 'users.form.email' | translate }}</label>
          <input
            id="email"
            type="email"
            pInputText
            class="w-full"
            formControlName="email"
            [class.ng-invalid]="userForm.get('email')?.invalid && userForm.get('email')?.touched"
          />
          <small class="text-red-500 block text-xs" *ngIf="errors.has('email')">{{ errors.get('email') }}</small>
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="firstName" class="block mb-1 text-sm">{{ 'users.form.firstName' | translate }}</label>
          <input
            id="firstName"
            type="text"
            pInputText
            class="w-full"
            formControlName="firstName"
            [class.ng-invalid]="userForm.get('firstName')?.invalid && userForm.get('firstName')?.touched"
          />
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="lastName" class="block mb-1 text-sm">{{ 'users.form.lastName' | translate }}</label>
          <input
            id="lastName"
            type="text"
            pInputText
            class="w-full"
            formControlName="lastName"
            [class.ng-invalid]="userForm.get('lastName')?.invalid && userForm.get('lastName')?.touched"
          />
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="group" class="block mb-1 text-sm">{{ 'users.form.group' | translate }}</label>
          <p-select
            id="group"
            [options]="groups"
            formControlName="group"
            class="w-full"
            [class.ng-invalid]="userForm.get('group')?.invalid && userForm.get('group')?.touched"
            [placeholder]="'primeng.choose' | translate"
          ></p-select>
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="role" class="block mb-1 text-sm">{{ 'users.form.role' | translate }}</label>
          <p-select
            id="role"
            [options]="roles"
            formControlName="role"
            class="w-full"
            [class.ng-invalid]="userForm.get('role')?.invalid && userForm.get('role')?.touched"
            [placeholder]="'primeng.choose' | translate"
          ></p-select>
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="organization" class="block mb-1 text-sm">{{ 'users.form.organization' | translate }}</label>
          <input
            id="organization"
            type="text"
            pInputText
            class="w-full"
            formControlName="organization"
            [class.ng-invalid]="userForm.get('organization')?.invalid && userForm.get('organization')?.touched"
          />
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="responsibleUnit" class="block mb-1 text-sm">{{ 'users.form.responsibleUnit' | translate }}</label>
          <input
            id="responsibleUnit"
            type="text"
            pInputText
            class="w-full"
            formControlName="responsibleUnit"
            (input)="inputResponsibleUnit()"
            [class.ng-invalid]="userForm.get('responsibleUnit')?.invalid && userForm.get('responsibleUnit')?.touched"
          />
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="time" class="block mb-1 text-sm">{{ 'users.form.time' | translate }}</label>
          <p-select
            id="time"
            [options]="time"
            formControlName="time"
            class="w-full"
            [class.ng-invalid]="userForm.get('time')?.invalid && userForm.get('time')?.touched"
            [placeholder]="'primeng.choose' | translate"
          ></p-select>
        </div>
      </div>

      <div class="w-full">
        <div class="mb-2">
          <label for="locale" class="block mb-1 text-sm">{{ 'users.form.locale' | translate }}</label>
          <p-select
            id="locale"
            [options]="locale"
            formControlName="locale"
            class="w-full"
            [class.ng-invalid]="userForm.get('locale')?.invalid && userForm.get('locale')?.touched"
            [placeholder]="'primeng.choose' | translate"
          ></p-select>
        </div>
      </div>

      <div class="w-full col-span-full">
        <div class="mb-2" formArrayName="phone">
          <label class="block mb-1 text-sm">{{ 'users.form.phone' | translate }}</label>
          <div *ngFor="let phoneControl of phone.controls; let i = index" class="flex mb-2">
            <input
              type="text"
              pInputText
              [formControlName]="i"
              class="flex-1"
              [class.ng-invalid]="phoneControl.invalid && phoneControl.touched"
            />
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="p-button-danger ml-2"
              (click)="removePhone(i)"
            ></button>
          </div>
          <button
            pButton
            type="button"
            icon="pi pi-plus"
            [label]="'users.form.addPhone' | translate"
            class="p-button-secondary"
            (click)="addPhone()"
            [disabled]="phone.controls.length >= 5"
          ></button>
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-2 mt-4">
      <button
        pButton
        type="button"
        [label]="'primeng.cancel' | translate"
        class="p-button-outlined"
        (click)="cancel()"
      ></button>
      <button
        pButton
        type="submit"
        [label]="(editMode ? 'users.form.save' : 'users.form.add') | translate"
        [disabled]="userForm.invalid"
      ></button>
    </div>
  </form>
</div>
