import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { RqaLayoutComponent } from './layout/component/app.layout';

@Component({
  selector: 'rqa-root',

  templateUrl: './app.component.html',

  styleUrls: ['./app.component.scss'],

  standalone: true,

  imports: [CommonModule, RouterModule, TranslateModule, RqaLayoutComponent]
})
export class AppComponent {
  title = 'Panel administracyjny';

  sidebarVisible = false;

  private translate = inject(TranslateService);

  constructor(
    private meta: Meta,

    private titleService: Title
  ) {
    this.setLanguage();

    this.initTranslations();
  }

  onMenuButtonClick() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  private setLanguage() {
    this.meta.addTags([
      { name: 'description', content: 'Panel administracyjny' },

      { name: 'author', content: 'PAŻP' }
    ]);

    this.titleService.setTitle('Administration panel');
  }

  private initTranslations() {
    this.translate.setDefaultLang('pl');

    this.translate.use('pl');
  }
}
