import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {DetailedMessageDTO, MessageRequest} from '../model/admin-dto';

@Injectable()
export class MessagesService {
  PATH = 'api/message';

  constructor(private http: HttpClient) {}

  getSentMessages(): Observable<DetailedMessageDTO[]> {
    return this.http.get<DetailedMessageDTO[]>(`${this.PATH}/list`);
  }

  sendMessage(message: MessageRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.PATH}`, message);
  }
}
