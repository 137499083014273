import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private requestCountSubject = new BehaviorSubject<number>(0);

  loading$: Observable<boolean> = this.requestCountSubject.pipe(map((count) => count > 0));

  startLoading(): void {
    this.requestCountSubject.next(this.requestCountSubject.value + 1);
  }

  stopLoading(): void {
    const newCount = Math.max(0, this.requestCountSubject.value - 1);

    this.requestCountSubject.next(newCount);
  }
}
