import { Component, inject, OnInit } from '@angular/core';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { YesNoPipe } from 'src/app/pipes/yes-no.pipe';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { DatePickerModule } from 'primeng/datepicker';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NoPolishCharsDirective } from 'src/app/shared/directives/no-polish-chars.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PrimeNG } from 'primeng/config';

import { SpecialDateDTO } from '../../model/admin-dto';
import { SpecialDateService } from '../../services/special-date.service';

@Component({
  selector: 'rqa-special-dates',
  templateUrl: './special-dates.component.html',
  styleUrls: ['./special-dates.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    YesNoPipe,
    TableModule,
    ButtonModule,
    DynamicDialogModule,
    PanelModule,
    DatePickerModule,
    FloatLabelModule,
    InputTextModule,
    CheckboxModule,
    NoPolishCharsDirective,
    TranslateModule
  ],
  providers: [SpecialDateService, DialogService],
  standalone: true
})
export class SpecialDatesComponent implements OnInit {
  private specialDateService = inject(SpecialDateService);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private primengConfig = inject(PrimeNG);
  specialDates: SpecialDateDTO[] = [];
  dialogRef: DynamicDialogRef | undefined;
  form: FormGroup = this.fb.group({
    date: this.fb.control('', Validators.required),
    reason: this.fb.control('', Validators.required),
    repeatable: this.fb.control(false, Validators.required)
  });
  minDate = new Date();

  ngOnInit(): void {
    this.getSpecialDates();
    this.translateService.get('primeng').subscribe((res) => this.primengConfig.setTranslation(res));
  }

  change(specialDateDTO: SpecialDateDTO): void {
    const active = specialDateDTO.active;

    specialDateDTO.active = !active;
    this.specialDateService.updateActiveValue(specialDateDTO).subscribe(() => {
      this.getSpecialDates();
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const payload = {
        ...this.form.value,
        date: this.form.value.date.toISOString().split('T')[0],
        active: true
      };

      this.specialDateService.createSpecialDate(payload).subscribe(() => {
        this.getSpecialDates();
        this.form.reset();
      });
    }
  }

  private getSpecialDates() {
    this.specialDateService.getSpecialDates().subscribe((value) => {
      this.specialDates = value;
    });
  }
}
