import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { EditorModule } from 'primeng/editor';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';

import { MessagesService } from '../../../services/messages.service';
import { MessageRequest } from '../../../model/admin-dto';

interface Recipient {
  name: string;
  code: string;
}

@Component({
  selector: 'rqa-create-message',
  templateUrl: './create-message.component.html',
  styles: [
    `
      ::ng-deep .multiselect-custom .p-multiselect-token-label {
        max-width: none;
      }

      ::ng-deep .multiselect-custom .p-multiselect-label {
        white-space: normal !important;
        height: auto;
        min-height: 2.5rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        align-items: center;
        padding: 0.25rem 0.75rem;
      }

      ::ng-deep .multiselect-custom .p-multiselect-token {
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
      }
    `
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    EditorModule,
    TranslateModule,
    CheckboxModule,
    MultiSelectModule
  ],
  providers: [DialogService, MessagesService]
})
export class CreateMessageComponent implements OnInit {
  message: MessageRequest = {
    recipient: [],
    content: '',
    sendToAll: false
  };

  availableRecipients: Recipient[] = [];

  constructor(
    private messagesService: MessagesService,
    private translate: TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.config.data?.messageList) {
      this.message.recipient = this.config.data.messageList;
      this.availableRecipients = this.config.data.messageList.map((username: string) => ({
        name: username,
        code: username
      }));
    }
  }

  onSendToAllChange() {
    if (this.message.sendToAll) {
      this.message.recipient = [];
    }
  }

  send() {
    this.messagesService.sendMessage(this.message).subscribe((response) => {
      if (response) {
        this.ref.close(true);
      }
    });
  }

  cancel() {
    this.ref.close(false);
  }
}
