import { Component, ElementRef } from '@angular/core';

import { RqaMenuComponent } from './app.menu';

@Component({
  selector: 'rqa-sidebar',

  standalone: true,

  imports: [RqaMenuComponent],

  template: `
    <div class="layout-sidebar">
      <rqa-menu></rqa-menu>
    </div>
  `
})
export class RqaSidebarComponent {
  constructor(public el: ElementRef) {}
}
