import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Table, TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MenuModule} from 'primeng/menu';
import {CheckboxChangeEvent, CheckboxModule} from 'primeng/checkbox';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MenuItem} from 'primeng/api';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ConfirmModalComponent} from 'src/app/modals/confirm-modal/confirm-modal.component';
import {TooltipModule} from 'primeng/tooltip';
import {SelectChangeEvent, SelectModule} from 'primeng/select';

import {CreateMessageComponent} from '../messages-list/create-message/create-message.component';
import {KeycloakInfoService} from '../../services/keycloak-info.service';
import {BlockUserDTO, UserDTO} from '../../model/admin-dto';
import {UsersService} from '../../services/users.service';

import {AddUserComponent} from './add-user/add-user.component';

@Component({
  selector: 'rqa-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    MenuModule,
    CheckboxModule,
    TranslateModule,
    TooltipModule,
    SelectModule,
    TableModule,
    TableModule,
    TableModule
  ],
  providers: [UsersService, KeycloakInfoService, DialogService, TooltipModule],
  standalone: true
})
export class UsersComponent implements OnInit {
  constructor(
    private usersService: UsersService,
    private dialogService: DialogService,
    private keycloakInfoService: KeycloakInfoService,
    private translate: TranslateService
  ) {
  }

  @ViewChild('usersTable') pTable!: Table;
  users: UserDTO[] = [];
  rows = 10;
  first = 0;
  messageList: string[] = [];
  dialogRef: DynamicDialogRef | undefined;

  roles: string[] = [];
  groups: string[] = [];
  time: string[] = [];
  locale: string[] = [];
  rowMenuItems: MenuItem[] = [];

  private static clearUserPage() {
    return [];
  }

  ngOnInit(): void {
    this.getUsers();
    this.keycloakInfoService.getRoles().subscribe((value) => {
      this.roles = value;
    });
    this.keycloakInfoService.getGroups().subscribe((value) => {
      this.groups = value;
    });
    this.keycloakInfoService.getLocale().subscribe((value) => {
      this.locale = value;
    });
    this.keycloakInfoService.getTime().subscribe((value) => {
      this.time = value;
    });
  }

  getUsers() {
    this.users = UsersComponent.clearUserPage();
    this.usersService.getUsersList().subscribe((response) => {
      this.users = response;
    });
  }

  openAdd() {
    this.dialogRef = this.dialogService.open(AddUserComponent, {
      header: this.translate.instant('users.dialog.add.title'),
      width: '60%',
      maximizable: true,
      modal: true,
      data: {
        locale: this.locale,
        groups: this.groups,
        roles: this.roles,
        time: this.time
      }
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.getUsers();
      }
    });
  }

  openEdit(user: UserDTO) {
    this.dialogRef = this.dialogService.open(AddUserComponent, {
      header: this.translate.instant('users.dialog.edit.title'),
      width: '75%',
      maximizable: true,
      modal: true,
      data: {
        editMode: true,
        user: user,
        locale: this.locale,
        groups: this.groups,
        roles: this.roles,
        time: this.time
      }
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.getUsers();
      }
    });
  }

  openBlock(user: UserDTO) {
    this.dialogRef = this.dialogService.open(ConfirmModalComponent, {
      header: user.enabled
        ? this.translate.instant('users.dialog.block.title')
        : this.translate.instant('users.dialog.unblock.title'),
      width: '450px',
      modal: true,
      data: {
        content: user.enabled
          ? this.translate.instant('users.dialog.block.message')
          : this.translate.instant('users.dialog.unblock.message')
      }
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result) {
        const block: BlockUserDTO = { enabled: !user.enabled, username: user.username };

        this.usersService.blockUser(block).subscribe((response) => {
          if (response) {
            this.getUsers();
          }
        });
      }
    });
  }

  addToMailList(username: string, event: CheckboxChangeEvent): void {
    if (event.checked) {
      this.messageList.push(username);
    } else {
      const index = this.messageList.indexOf(username);

      if (index > -1) {
        this.messageList.splice(index, 1);
      }
    }
  }

  sentMessage() {
    this.dialogRef = this.dialogService.open(CreateMessageComponent, {
      header: this.translate.instant('messages.create.title'),
      width: '70%',
      modal: true,
      data: {
        messageList: this.messageList
      }
    });

    this.dialogRef.onClose.subscribe(() => {
      this.messageList = [];
    });
  }

  openDelete(user: UserDTO) {
    this.dialogRef = this.dialogService.open(ConfirmModalComponent, {
      header: this.translate.instant('users.dialog.delete.title'),
      width: '450px',
      modal: true,
      data: {
        content: this.translate.instant('users.dialog.delete.message')
      }
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.usersService.deleteUser(user.username).subscribe((response) => {
          if (response) {
            this.getUsers();
          }
        });
      }
    });
  }

  openVerify(user: UserDTO) {
    this.dialogRef = this.dialogService.open(ConfirmModalComponent, {
      header: this.translate.instant('users.dialog.verify.title'),
      width: '450px',
      modal: true,
      data: {
        content: this.translate.instant('users.dialog.verify.message')
      }
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.usersService.sendVerifyEmail(user.username).subscribe();
      }
    });
  }

  onShowMenu(user: UserDTO): void {
    this.rowMenuItems = [
      {
        label: this.translate.instant('users.actions.edit'),
        icon: 'pi pi-pencil',
        command: () => this.openEdit(user)
      },
      {
        label: user.enabled
          ? this.translate.instant('users.actions.block')
          : this.translate.instant('users.actions.unblock'),
        icon: user.enabled ? 'pi pi-lock' : 'pi pi-lock-open',
        command: () => this.openBlock(user)
      },
      {
        label: this.translate.instant('users.actions.delete'),
        icon: 'pi pi-trash',
        command: () => this.openDelete(user)
      },
      {
        label: this.translate.instant('users.actions.verify'),
        icon: 'pi pi-envelope',
        command: () => this.openVerify(user)
      }
    ];
  }

  filterUsers($event: Event, matchType: string): void {
    this.pTable.filterGlobal(($event.target as HTMLInputElement).value, matchType);
  }

  refresh() {
    this.first = 0;
    this.getUsers();
  }

  onSelect($event: SelectChangeEvent) {
    this.pTable.filter($event.value, 'enabled', 'equals');
  }
}
