<div class="card">
  <div class="flex pb-2 w-full">
    <p-panel [header]="'specialDates.panel.title' | translate" [toggleable]="true" class="w-full" [collapsed]="true">
      <form class="w-full mt-3" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:space-x-4">
          <div class="flex space-x-4 flex-1">
            <div class="flex-1">
              <p-floatlabel>
                <p-datepicker
                  showIcon
                  iconDisplay="input"
                  id="datepicker"
                  formControlName="date"
                  [styleClass]="'w-full'"
                  dateFormat="yy-mm-dd"
                  [minDate]="minDate"
                />
                <label for="datepicker">{{ 'specialDates.form.date' | translate }}</label>
              </p-floatlabel>
            </div>
            <div class="flex-1">
              <p-floatlabel>
                <input
                  pInputText
                  id="reason"
                  autocomplete="off"
                  formControlName="reason"
                  class="w-full"
                  maxlength="30"
                  rqaNoPolishChars
                />
                <label for="reason">{{ 'specialDates.form.reason' | translate }}</label>
              </p-floatlabel>
            </div>
          </div>
          <div class="flex items-center justify-between space-x-4">
            <div class="flex items-center">
              <p-checkbox inputId="repeatable" name="repeatable" formControlName="repeatable" [binary]="true" />
              <label for="repeatable" class="ml-2">{{ 'specialDates.form.repeatable' | translate }}</label>
            </div>
            <div class="flex space-x-2">
              <button
                pButton
                type="submit"
                [label]="'specialDates.form.buttons.add' | translate"
                icon="pi pi-check"
                [disabled]="!form.valid"
              ></button>
              <button
                pButton
                type="button"
                [label]="'specialDates.form.buttons.clear' | translate"
                severity="secondary"
                icon="pi pi-times"
                (click)="form.reset()"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </p-panel>
  </div>
  <p-table [value]="specialDates" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'specialDates.table.header.number' | translate }}</th>
        <th>{{ 'specialDates.table.header.year' | translate }}</th>
        <th>{{ 'specialDates.table.header.date' | translate }}</th>
        <th>{{ 'specialDates.table.header.reason' | translate }}</th>
        <th>{{ 'specialDates.table.header.repeatable' | translate }}</th>
        <th>{{ 'specialDates.table.header.actions' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-date let-i="rowIndex">
      <tr>
        <td scope="row">{{ i + 1 }}</td>
        <td [ngClass]="date.active ? 'text-success' : 'text-danger'">{{ date.date | date: 'yyyy' }}</td>
        <td [ngClass]="date.active ? 'text-success' : 'text-danger'">{{ date.date | date: 'MM-dd' }}</td>
        <td>{{ date?.reason }}</td>
        <td>{{ date?.repeatable | yesNo }}</td>
        <td colspan="2">
          <p-button
            [label]="'specialDates.table.actions.delete' | translate"
            icon="pi pi-times"
            (click)="change(date)"
            severity="danger"
            size="small"
          />
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
