import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {BlockUserDTO, ResponseDTO, UserDTO} from '../model/admin-dto';

@Injectable()
export class UsersService {
  PATH = 'api/users';

  constructor(private http: HttpClient) {}

  getUsersList(): Observable<UserDTO[]> {
    return this.http.get<UserDTO[]>(`${this.PATH}`);
  }

  addUser(user: UserDTO): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(`${this.PATH}`, user);
  }

  blockUser(blockUserDTO: BlockUserDTO): Observable<boolean> {
    return this.http.put<boolean>(`${this.PATH}/block`, blockUserDTO);
  }

  updateUser(user: UserDTO): Observable<boolean> {
    return this.http.put<boolean>(`${this.PATH}`, user);
  }

  deleteUser(username: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.PATH}/${username}`);
  }

  sendVerifyEmail(username: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.PATH}/verify?username=${username}`, {});
  }
}
