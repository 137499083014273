import { Routes } from '@angular/router';

import { SpecialDatesComponent } from './view/special-dates/special-dates.component';
import { canActivateAuthRole } from './guards/auth.guard';
import { UsersComponent } from './view/users/users.component';
import { MessagesListComponent } from './view/messages-list/messages-list.component';
import { HelpComponent } from './view/help/help.component';
import { HomeComponent } from './view/home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [canActivateAuthRole],
    pathMatch: 'full'
  },
  {
    path: 'dates',
    component: SpecialDatesComponent,
    canActivate: [canActivateAuthRole],
    pathMatch: 'full'
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [canActivateAuthRole],
    pathMatch: 'full'
  },
  {
    path: 'messages',
    component: MessagesListComponent,
    canActivate: [canActivateAuthRole],
    pathMatch: 'full'
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [canActivateAuthRole],
    pathMatch: 'full'
  }
];
