import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';

import { UserDTO } from '../../../model/admin-dto';
import { UsersService } from '../../../services/users.service';
import { KeycloakInfoService } from '../../../services/keycloak-info.service';

const userNotAdded = 'User not added';
const userNotEdited = 'User not edited';

@Component({
  selector: 'rqa-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    SelectModule,
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KeycloakInfoService, UsersService],
  standalone: true
})
export class AddUserComponent implements OnInit {
  phone = new UntypedFormArray([]);
  show = false;
  message = '';
  errors: Map<string, string> = new Map<string, string>();
  userForm = this.fb.group({});

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  get user(): UserDTO | undefined {
    return this.config.data?.user;
  }

  get editMode(): boolean {
    return this.config.data?.editMode || false;
  }

  get roles(): string[] {
    return this.config.data?.roles || [];
  }

  get groups(): string[] {
    return this.config.data?.groups || [];
  }

  get time(): string[] {
    return this.config.data?.time || [];
  }

  get locale(): string[] {
    return this.config.data?.locale || [];
  }

  ngOnInit(): void {
    if (this.groups.length) {
      this.userForm = this.fb.group({
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('^[a-z][a-zA-Z0-9._-]{8,20}$')
          ]
        ],
        firstName: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
            Validators.pattern("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ - . ']{2,20}$")
          ]
        ],
        lastName: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
            Validators.pattern("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ - . ']{2,20}$")
          ]
        ],
        email: ['', [Validators.required, Validators.email]],
        group: [this.groups[0], [Validators.required]],
        role: [this.roles[0], [Validators.required]],
        organization: [
          '',
          [Validators.maxLength(50), Validators.pattern("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 - . ']{0,50}$")]
        ],
        responsibleUnit: [
          '',
          [
            Validators.required,
            Validators.maxLength(8),
            Validators.minLength(2),
            Validators.pattern('^[0-9]*[A-Z]{2,8}$')
          ]
        ],
        time: [this.time[0], [Validators.required]],
        locale: [this.locale[0], [Validators.required]],
        phone: this.fb.array([
          this.fb.control('', [
            Validators.required,
            Validators.pattern('^[+]?[0-9 ]{7,19}$'),
            Validators.minLength(7),
            Validators.maxLength(19)
          ])
        ])
      });
    }
    this.phone = this.userForm.get('phone') as UntypedFormArray;
    if (this.editMode) {
      this.setFormValues();
    }
  }

  private setFormValues() {
    if (this.user) {
      this.userForm = this.fb.group({
        username: [
          this.user.username,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('^[a-z][a-zA-Z0-9._-]{8,20}$')
          ]
        ],
        firstName: [
          this.user.firstName,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
            Validators.pattern("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ - . ']{2,20}$")
          ]
        ],
        lastName: [
          this.user.lastName,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
            Validators.pattern("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -. ']{2,20}$")
          ]
        ],
        email: [this.user.email, [Validators.required, Validators.email]],
        group: [this.user.group, [Validators.required]],
        role: [this.user.role, [Validators.required]],
        organization: [
          this.user.organization,
          [Validators.maxLength(50), Validators.pattern("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 - . ']{0,50}$")]
        ],
        responsibleUnit: [
          this.user.responsibleUnit,
          [
            Validators.required,
            Validators.maxLength(8),
            Validators.minLength(2),
            Validators.pattern('^[0-9]*[A-Z]{2,8}$')
          ]
        ],
        time: [this.user.time, [Validators.required]],
        locale: [this.user.locale, [Validators.required]],
        phone: this.fb.array([])
      });
      this.phone = this.userForm.get('phone') as UntypedFormArray;
      this.user.phone.forEach((item) => {
        this.phone.push(
          this.fb.control(item, [
            Validators.required,
            Validators.pattern('^[+]?[0-9 ]{7,19}$'),
            Validators.minLength(7),
            Validators.maxLength(19)
          ])
        );
      });
    }
  }

  handleFormSubmit() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid) {
      return;
    }
    if (this.editMode) {
      this.editUser();
    } else {
      this.addUser();
    }
  }

  addPhone() {
    if (this.phone.controls.length <= 5) {
      this.phone.push(
        this.fb.control('', [
          Validators.required,
          Validators.pattern('^[+]?[0-9 ]{7,19}$'),
          Validators.minLength(7),
          Validators.maxLength(19)
        ])
      );
    }
  }

  removePhone(phoneValue: number) {
    this.phone.removeAt(phoneValue);
  }

  private editUser() {
    this.errors = new Map<string, string>();
    const value = this.userForm.value as UserDTO;

    this.usersService.updateUser(value).subscribe(
      (response) => {
        if (response) {
          this.ref.close(true);
        }
      },
      (error) => {
        if (error.error) {
          Object.keys(error.error).forEach((key) => this.errors.set(key, error.error[key]));
        }
        this.handleBackendErrors(userNotEdited);
      }
    );
  }

  private addUser() {
    this.errors = new Map<string, string>();
    const value = this.userForm.value as UserDTO;

    this.usersService.addUser(value).subscribe(
      (response) => {
        if (response.message === 'User added') {
          this.ref.close(true);
        }
      },
      (error) => {
        if (error.error) {
          Object.keys(error.error).forEach((key) => this.errors.set(key, error.error[key]));
        }
        this.handleBackendErrors(userNotAdded);
      }
    );
  }

  private handleBackendErrors(title: string) {
    // Handle errors here
  }

  inputUsername() {
    const value = this.userForm.controls.username.value.toLowerCase();

    this.userForm.controls.username.setValue(value);
  }

  inputResponsibleUnit() {
    const value = this.userForm.controls.responsibleUnit.value.toUpperCase();

    this.userForm.controls.responsibleUnit.setValue(value);
  }

  cancel() {
    this.ref.close(false);
  }
}
