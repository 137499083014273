import { Component } from '@angular/core';

@Component({
  standalone: true,

  selector: 'rqa-footer',

  template: `<div class="layout-footer"></div>`
})
export class RqaFooterComponent {}
