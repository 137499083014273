import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TableModule, TablePageEvent} from 'primeng/table';
import {DialogService} from 'primeng/dynamicdialog';
import {InfoModalComponent} from 'src/app/modals/info-modal/info-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';

import {MessagesService} from '../../services/messages.service';
import {DetailedMessageDTO} from '../../model/admin-dto';

@Component({
  selector: 'rqa-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss'],
  imports: [CommonModule, FormsModule, TableModule, TranslateModule, ButtonModule, TooltipModule, TableModule],
  providers: [MessagesService, DialogService],
  standalone: true
})
export class MessagesListComponent implements OnInit {
  private messagesService = inject(MessagesService);
  private dialogService = inject(DialogService);
  messages: DetailedMessageDTO[] = [];
  rows = 10;
  first = 0;

  ngOnInit(): void {
    this.getMessages();
  }

  getMessages() {
    this.messagesService.getSentMessages().subscribe((value) => {
      this.messages = value;
    });
  }

  search() {
    this.getMessages();
  }

  pageChange(event: TablePageEvent) {
    this.first = event.first;
    this.rows = event.rows;
  }

  openMessageDetails(content: string | DocumentFragment) {
    const ref = this.dialogService.open(InfoModalComponent, {
      data: {
        content: content
      },
      header: 'Message Details',
      width: '70%'
    });
  }
}
