import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutService } from '../service/layout.service';

@Component({
  selector: 'rqa-topbar',

  standalone: true,

  imports: [RouterModule, CommonModule, StyleClassModule, TranslateModule],
  providers: [KeycloakService],

  template: ` <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button class="layout-menu-button layout-topbar-action" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
      </button>

      <a class="layout-topbar-logo" routerLink="/">
        <img src="assets/svg/pansa-logo.svg" alt="PANSA logo" />
      </a>
    </div>

    <div class="layout-topbar-actions">
      <div class="layout-config-menu">
        <button type="button" class="layout-topbar-action" (click)="toggleLanguage()">
          <i class="pi pi-globe"></i>
          <span>{{ currentLanguage }}</span>
        </button>
        <button type="button" class="layout-topbar-action" (click)="toggleDarkMode()">
          <i
            [ngClass]="{ 'pi ': true, 'pi-moon': layoutService.isDarkTheme(), 'pi-sun': !layoutService.isDarkTheme() }"
          ></i>
        </button>
        <button type="button" class="layout-topbar-action" (click)="logout()">
          <i class="pi pi-sign-out"></i>
          <span>{{ 'menu.logout' | translate }}</span>
        </button>
      </div>
    </div>
  </div>`
})
export class RqaTopbarComponent {
  items!: MenuItem[];
  currentLanguage: string = 'PL';

  constructor(
    public layoutService: LayoutService,
    private translateService: TranslateService,
    private keycloakService: KeycloakService
  ) {
    // Initialize with current language from translate service
    this.currentLanguage = this.translateService.currentLang?.toUpperCase() || 'PL';
  }

  toggleDarkMode() {
    this.layoutService.layoutConfig.update((state) => ({ ...state, darkTheme: !state.darkTheme }));
  }

  toggleLanguage() {
    this.currentLanguage = this.currentLanguage === 'PL' ? 'EN' : 'PL';
    this.translateService.use(this.currentLanguage.toLowerCase());
    // Store the language preference
    (window as Window & typeof globalThis).localStorage.setItem(
      'preferredLanguage',
      this.currentLanguage.toLowerCase()
    );
  }

  logout() {
    this.keycloakService.logout();
  }
}
