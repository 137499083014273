import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

export const RqaPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{pink.50}',
      100: '{pink.100}',
      200: '{pink.200}',
      300: '{pink.300}',
      400: '{pink.400}',
      500: '{pink.500}',
      600: '{pink.600}',
      700: '{pink.700}',
      750: '{pink.750}',
      800: '{pink.800}',
      900: '{pink.900}',
      950: '{pink.950}'
    },
    colorScheme: {
      light: {
        primary: {
          color: '{pink.600}',
          inverseColor: '#ffffff',
          hoverColor: '{pink.700}',
          activeColor: '{pink.500}'
        },
        highlight: {
          background: '{pink.750}',
          focusBackground: '{pink.500}',
          color: '{pink.700}',
          focusColor: '{pink.500}'
        }
      },
      dark: {
        primary: {
          color: '{pink.400}',
          inverseColor: '#ffffff',
          hoverColor: '{pink.500}',
          activeColor: '{pink.300}'
        },
        highlight: {
          background: '{pink.500}',
          focusBackground: '{pink.300}',
          color: '#ffffff',
          focusColor: '#ffffff'
        }
      }
    }
  }
});
