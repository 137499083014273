import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
  pure: true,
  standalone: true
})
export class YesNoPipe implements PipeTransform {
  transform(value: string | boolean): string {
    return value ? 'Yes' : 'No';
  }
}
