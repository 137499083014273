import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { interval, Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-home',
  standalone: true,
  imports: [CommonModule, DatePipe, TranslateModule],
  providers: [KeycloakService],
  template: `
    <div class="card">
      <div class="text-3xl font-bold mb-4">
        {{ 'home.welcome' | translate }} {{ userName || ('home.user' | translate) }}!
      </div>
      <div class="text-xl mb-2">{{ 'home.today' | translate }}: {{ currentDate | date: 'long' : '' : 'pl' }}</div>
      <div class="text-lg text-500">{{ 'home.utc' | translate }}: {{ currentDate | date: 'long' : 'UTC' : 'en' }}</div>
    </div>
  `
})
export class HomeComponent implements OnInit, OnDestroy {
  userName?: string;
  currentDate: Date = new Date();
  private clockSubscription?: Subscription;

  constructor(private readonly keycloak: KeycloakService) {}

  async ngOnInit() {
    try {
      if (await this.keycloak.isLoggedIn()) {
        const profile = await this.keycloak.getKeycloakInstance().loadUserProfile();

        this.userName = profile.firstName ?? undefined;
      }
    } catch (error) {
      console.error('Error loading user profile:', error);
    }

    // Start the clock update
    this.clockSubscription = interval(1000).subscribe(() => {
      this.currentDate = new Date();
    });
  }

  ngOnDestroy() {
    // Cleanup subscription when component is destroyed
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
    }
  }
}
