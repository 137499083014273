import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'rqa-loading',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  template: `
    <div class="loading-overlay" *ngIf="loadingService.loading$ | async">
      <div class="loading-spinner">
        <p-progressSpinner strokeWidth="4" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  `,
  styles: `
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
})
export class LoadingComponent {
  constructor(public loadingService: LoadingService) {}
}
