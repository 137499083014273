<div class="card">
  <p-table
    #usersTable
    [value]="users"
    [tableStyle]="{ 'min-width': '50rem', 'max-height': '75rem' }"
    [scrollable]="true"
    [scrollHeight]="'600px'"
    [breakpoint]="'960px'"
    dataKey="username"
    [globalFilterFields]="['firstName', 'lastName', 'username']"
    styleClass="p-datatable-sm"
  >
    <ng-template pTemplate="header">
      <tr>
        <th colspan="14">
          <div class="flex items-center justify-between mb-3">
            <div class="flex items-center gap-2 w-1/2">
              <div class="flex-1">
                <label for="search" class="block mb-1 text-sm">{{ 'users.search.label' | translate }}</label>
                <input
                  pInputText
                  id="search"
                  type="text"
                  (input)="filterUsers($event, 'contains')"
                  [placeholder]="'users.search.placeholder' | translate"
                  class="w-full"
                />
              </div>

              <div>
                <label for="enabled" class="block mb-1 text-sm">{{ 'users.table.header.enabled' | translate }}</label>
                <p-select
                  id="enabled"
                  [options]="[
                    { label: 'users.search.enabled.yes' | translate, value: true },
                    { label: 'users.search.enabled.no' | translate, value: false }
                  ]"
                  class="w-32"
                  (onChange)="onSelect($event)"
                ></p-select>
              </div>
              <button pButton type="button" icon="pi pi-refresh" (click)="refresh()" class="self-end"></button>
            </div>
          </div>
        </th>
      </tr>

      <tr>
        <th></th>

        <th>{{ 'users.table.header.number' | translate }}</th>

        <th pSortableColumn="username">
          {{ 'users.table.header.username' | translate }}
          <p-sortIcon field="username"></p-sortIcon>
        </th>

        <th pSortableColumn="firstName">
          {{ 'users.table.header.firstName' | translate }}
          <p-sortIcon field="firstName"></p-sortIcon>
        </th>

        <th pSortableColumn="lastName">
          {{ 'users.table.header.lastName' | translate }}
          <p-sortIcon field="lastName"></p-sortIcon>
        </th>

        <th pSortableColumn="email">
          {{ 'users.table.header.email' | translate }}
          <p-sortIcon field="email"></p-sortIcon>
        </th>

        <th pSortableColumn="organization">
          {{ 'users.table.header.organization' | translate }}
          <p-sortIcon field="organization"></p-sortIcon>
        </th>

        <th pSortableColumn="phone">
          {{ 'users.table.header.phone' | translate }}
          <p-sortIcon field="phone"></p-sortIcon>
        </th>

        <th pSortableColumn="group">
          {{ 'users.table.header.group' | translate }}
          <p-sortIcon field="group"></p-sortIcon>
        </th>

        <th pSortableColumn="time">
          {{ 'users.table.header.time' | translate }}
          <p-sortIcon field="time"></p-sortIcon>
        </th>

        <th pSortableColumn="responsibleUnit">
          {{ 'users.table.header.respUnit' | translate }}
          <p-sortIcon field="responsibleUnit"></p-sortIcon>
        </th>

        <th pSortableColumn="role">
          {{ 'users.table.header.role' | translate }}
          <p-sortIcon field="role"></p-sortIcon>
        </th>

        <th pSortableColumn="enabled">
          {{ 'users.table.header.enabled' | translate }}
          <p-sortIcon field="enabled"></p-sortIcon>
        </th>

        <th></th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user let-i="rowIndex">
      <tr>
        <td>
          <p-checkbox
            [binary]="true"
            [ngModel]="messageList.includes(user.username)"
            (onChange)="addToMailList(user.username, $event)"
          ></p-checkbox>
        </td>

        <td>{{ i + 1 }}</td>

        <td>{{ user.username }}</td>

        <td>{{ user.firstName }}</td>

        <td>{{ user.lastName }}</td>

        <td>{{ user.email }}</td>

        <td>{{ user.organization }}</td>

        <td>
          <div class="flex align-items-center">
            <span *ngIf="user.phone">{{ user.phone[0] }}</span>

            <div class="btn-group" *ngIf="user.phone && user.phone.length > 1">
              <a
                id="dropdownMenuPhone"
                data-toggle="dropdown"
                aria-haspopup="true"
                [pTooltip]="phoneNumbers"
                tooltipPosition="bottom"
                aria-expanded="false"
              >
                <i class="pi pi-angle-down"></i>
              </a>
              <ng-template #phoneNumbers>
                <div
                  class="dropdown-menu dropdown-menu-left"
                  style="right: 0; left: auto"
                  aria-labelledby="dropdownMenuPhone"
                >
                  <div *ngFor="let number of user.phone | slice: 1">
                    <div>{{ number }}</div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </td>

        <td>{{ user.group }}</td>

        <td>{{ user.time }}</td>

        <td>{{ user.responsibleUnit }}</td>

        <td>{{ user.role }}</td>

        <td class="text-center">
          <i
            [class]="user.enabled ? 'pi pi-check-circle text-green-500' : 'pi pi-times-circle text-red-500'"
            [pTooltip]="(user.enabled ? 'users.search.enabled.yes' : 'users.search.enabled.no') | translate"
          ></i>
        </td>

        <td>
          <div class="flex">
            <button
              pButton
              type="button"
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-sm"
              (click)="menu.toggle($event)"
            ></button>

            <p-menu
              #menu
              [popup]="true"
              [model]="rowMenuItems"
              [appendTo]="'body'"
              [baseZIndex]="1000"
              styleClass="custom-menu"
              (onShow)="onShowMenu(user)"
            ></p-menu>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template #footer pTemplate="footer">
      <tr>
        <td colspan="14">
          <div class="flex justify-start gap-2">
            <p-button
              variant="text"
              icon="pi pi-plus"
              [label]="'users.form.add' | translate"
              class="p-button-sm"
              (click)="openAdd()"
            />

            <p-button
              type="button"
              variant="text"
              icon="pi pi-envelope"
              [label]="'users.actions.sendMessage' | translate"
              class="p-button-sm"
              [disabled]="messageList.length === 0"
              (click)="sentMessage()"
            />
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
