import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { RippleModule } from 'primeng/ripple';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, RippleModule, TranslateModule],
  template: `
    <ul class="layout-menu">
      <ng-container *ngFor="let item of model; let i = index">
        <li *ngIf="!item.separator" class="layout-menuitem-category">
          <h3>{{ item?.label ?? '' | translate }}</h3>
          <ul>
            <li *ngFor="let child of item?.items">
              <a
                [routerLink]="child.routerLink"
                routerLinkActive="active-route"
                [routerLinkActiveOptions]="{ exact: true }"
                class="p-ripple"
                pRipple
              >
                <i [class]="child.icon"></i>
                <span>{{ child?.label ?? '' | translate }}</span>
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="item.separator" class="menu-separator"></li>
      </ng-container>
    </ul>
  `
})
export class RqaMenuComponent implements OnInit {
  model: MenuItem[] = [];

  ngOnInit() {
    this.model = [
      {
        label: 'menu.title',
        items: [
          { label: 'menu.home', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
          { label: 'menu.specialDates', icon: 'pi pi-fw pi-calendar', routerLink: ['/dates'] },
          { label: 'menu.users', icon: 'pi pi-fw pi-users', routerLink: ['/users'] },
          { label: 'menu.messages', icon: 'pi pi-fw pi-envelope', routerLink: ['/messages'] },
          { label: 'menu.help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/help'] }
        ]
      }
    ];
  }
}
