import { Component, OnInit } from '@angular/core';
import { TabsModule } from 'primeng/tabs';
import { CommonModule } from '@angular/common';

import { KeycloakInfoService } from '../../services/keycloak-info.service';

import { OtherComponent } from './other/other.component';
import { EnglishComponent } from './english/english.component';

@Component({
  selector: 'rqa-help',
  template: `
    <p-tabs [(value)]="activeTab">
      <p-tablist>
        <p-tab value="0">English</p-tab>
        <p-tab value="1">Polish</p-tab>
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel value="0">
          <rqa-english *ngIf="activeTab === '0'"></rqa-english>
        </p-tabpanel>
        <p-tabpanel value="1">
          <rqa-other *ngIf="activeTab === '1'" [locale]="'PL'"></rqa-other>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  `,
  imports: [TabsModule, OtherComponent, EnglishComponent, CommonModule],
  providers: [KeycloakInfoService],
  standalone: true
})
export class HelpComponent implements OnInit {
  locale: string[] = [];
  activeTab: string = '0';

  constructor(private keycloakInfo: KeycloakInfoService) {}

  ngOnInit(): void {
    this.keycloakInfo.getLocale().subscribe((value) => {
      this.locale = value;
    });
  }
}
