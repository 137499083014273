<div class="card">
  <p-table
    [value]="messages"
    [tableStyle]="{ 'min-width': '50rem', 'max-height': '75rem' }"
    [scrollable]="true"
    [scrollHeight]="'600px'"
    [breakpoint]="'960px'"
    dataKey="sentTime"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'messages.list.recipient' | translate }}</th>
        <th>{{ 'messages.list.sentTime' | translate }}</th>
        <th>{{ 'messages.list.content' | translate }}</th>
        <th>{{ 'messages.list.read' | translate }}</th>
        <th style="width: 4rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-message let-expanded="expanded">
      <tr>
        <td>{{ message.recipient }}</td>
        <td>{{ message.sentTime | date: 'dd/MM/YYYY HH:MM:SS' }}</td>
        <td>{{ message.content | slice: 0 : 50 }}{{ message.content.length > 50 ? '...' : '' }}</td>
        <td class="text-center">
          <i
            [class]="message.read ? 'pi pi-check-circle text-green-500' : 'pi pi-times-circle text-red-500'"
            [pTooltip]="(message.read ? 'users.search.enabled.yes' : 'users.search.enabled.no') | translate"
          ></i>
        </td>
        <td>
          <button
            type="button"
            pButton
            [pRowToggler]="message"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template #expandedrow let-message>
      <tr>
        <td colspan="5">
          <div class="p-4">
            <div [innerHTML]="message.content"></div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
